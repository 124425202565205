import Utils from "./utils/utils.js";
import {Loader} from "pixi.js";

export default class ResourcesLoader {
  static #textures = {};
  static #assetsToLoad = {
    // path -> name of the texture
    [Utils.assetSpritePath("ui/logo.png")]: "logo",
    [Utils.assetSpritePath("ui/bg_main_menu.png")]: "bg-main-menu",
    [Utils.assetSpritePath("ui/title_main_menu.png")]: "title-main-menu",
    [Utils.assetSpritePath("ui/reflect_main_menu.png")]: "reflect-main-menu",
    [Utils.assetSpritePath("ui/bg_container.png")]: "bg-container",
    [Utils.assetSpritePath("ui/btn_background.png")]: "btn-background",
    [Utils.assetSpritePath("ui/btn_background_clicked.png")]: "btn-background-clicked",
    [Utils.assetSpritePath("ui/btn_quit.png")]: "btn-quit",
    [Utils.assetSpritePath("ui/coin.png")]: "coin",
    [Utils.assetSpritePath("ui/btn_help.png")]: "btn-help",
    [Utils.assetSpritePath("ui/progress_background.png")]: "progress-background",
    [Utils.assetSpritePath("ui/progress_steps_0.png")]: "progress-steps-0",
    [Utils.assetSpritePath("ui/progress_steps_1.png")]: "progress-steps-1",
    [Utils.assetSpritePath("ui/progress_steps_2.png")]: "progress-steps-2",
    [Utils.assetSpritePath("ui/progress_steps_3.png")]: "progress-steps-3",
    [Utils.assetSpritePath("ui/progress_fill.png")]: "progress-fill",
    [Utils.assetSpritePath("ui/progress_fill_top.png")]: "progress-fill-top",

    // player
    [Utils.assetSpritePath("biker/biker_01.png")]: "biker-00",
    [Utils.assetSpritePath("biker/biker_02.png")]: "biker-01",
    [Utils.assetSpritePath("biker/biker_03.png")]: "biker-02",
    [Utils.assetSpritePath("biker/game-over/gameover_00.png")]: "gameover-00",
    [Utils.assetSpritePath("biker/game-over/gameover_01.png")]: "gameover-01",
    [Utils.assetSpritePath("biker/game-over/gameover_02.png")]: "gameover-02",
    [Utils.assetSpritePath("biker/game-over/gameover_03.png")]: "gameover-03",
    [Utils.assetSpritePath("biker/game-over/gameover_04.png")]: "gameover-04",
    [Utils.assetSpritePath("biker/game-over/gameover_05.png")]: "gameover-05",

    // decors
    [Utils.assetSpritePath("decors/car_green_00.png")]: "car-green-00",
    [Utils.assetSpritePath("decors/car_red_00.png")]: "car-red-00",
    [Utils.assetSpritePath("decors/car_blue_00.png")]: "car-blue-00",
    [Utils.assetSpritePath("decors/car_police_00.png")]: "car-police-00",
    [Utils.assetSpritePath("decors/car_taxi_00.png")]: "car-taxi-00",
    [Utils.assetSpritePath("decors/firefighters_00.png")]: "firefighters-00",
    [Utils.assetSpritePath("decors/ambulance_00.png")]: "ambulance-00",
    [Utils.assetSpritePath("decors/bus_yellow_00.png")]: "bus-yellow-00",
    [Utils.assetSpritePath("decors/scooter_cyan_00.png")]: "scooter-cyan-00",
    [Utils.assetSpritePath("decors/scooter_green_00.png")]: "scooter-green-00",

    [Utils.assetSpritePath("decors/bubble.png")]: "bubble-00",

    [Utils.assetSpritePath("decors/bridge_00.png")]: "bridge-00",
    [Utils.assetSpritePath("decors/orange_light_00.png")]: "orange-light-00",
    [Utils.assetSpritePath("decors/blue_light_00.png")]: "blue-light-00",
    [Utils.assetSpritePath("decors/red_light_00.png")]: "red-light-00",

    // roads
    [Utils.assetSpritePath("roads/road_00.png")]: "road-00",
    [Utils.assetSpritePath("roads/road_01.png")]: "road-01",
    [Utils.assetSpritePath("roads/road_02.png")]: "road-02",
    [Utils.assetSpritePath("roads/road_03.png")]: "road-03",
    [Utils.assetSpritePath("roads/road_04.png")]: "road-04",
    [Utils.assetSpritePath("roads/road_05.png")]: "road-05",
    [Utils.assetSpritePath("roads/road_06.png")]: "road-06",
    [Utils.assetSpritePath("roads/road_07.png")]: "road-07",
    [Utils.assetSpritePath("roads/road_08.png")]: "road-08",
    [Utils.assetSpritePath("roads/road_09.png")]: "road-09",
    [Utils.assetSpritePath("roads/road_10.png")]: "road-10",
    [Utils.assetSpritePath("roads/road_11.png")]: "road-11",
    [Utils.assetSpritePath("roads/road_12.png")]: "road-12",
    [Utils.assetSpritePath("roads/road_13.png")]: "road-13",
    [Utils.assetSpritePath("roads/road_14.png")]: "road-14",
    [Utils.assetSpritePath("roads/road_15.png")]: "road-15",

    // Objects
    [Utils.assetSpritePath("objects/coin/coin_1_00.png")]: "coin-1-00",
    [Utils.assetSpritePath("objects/coin/coin_1_01.png")]: "coin-1-01",
    [Utils.assetSpritePath("objects/coin/coin_1_02.png")]: "coin-1-02",
    [Utils.assetSpritePath("objects/coin/coin_1_03.png")]: "coin-1-03",
    [Utils.assetSpritePath("objects/coin/coin_1_04.png")]: "coin-1-04",
    [Utils.assetSpritePath("objects/coin/coin_1_05.png")]: "coin-1-05",
    [Utils.assetSpritePath("objects/coin/coin_1_06.png")]: "coin-1-06",
    [Utils.assetSpritePath("objects/coin/coin_1_07.png")]: "coin-1-07",
    [Utils.assetSpritePath("objects/coin/coin_3_00.png")]: "coin-3-00",
    [Utils.assetSpritePath("objects/coin/coin_3_01.png")]: "coin-3-01",
    [Utils.assetSpritePath("objects/coin/coin_3_02.png")]: "coin-3-02",
    [Utils.assetSpritePath("objects/coin/coin_3_03.png")]: "coin-3-03",
    [Utils.assetSpritePath("objects/coin/coin_3_04.png")]: "coin-3-04",
    [Utils.assetSpritePath("objects/coin/coin_3_05.png")]: "coin-3-05",
    [Utils.assetSpritePath("objects/coin/coin_3_06.png")]: "coin-3-06",
    [Utils.assetSpritePath("objects/coin/coin_3_07.png")]: "coin-3-07",
    [Utils.assetSpritePath("objects/coin/coin_5_00.png")]: "coin-5-00",
    [Utils.assetSpritePath("objects/coin/coin_5_01.png")]: "coin-5-01",
    [Utils.assetSpritePath("objects/coin/coin_5_02.png")]: "coin-5-02",
    [Utils.assetSpritePath("objects/coin/coin_5_03.png")]: "coin-5-03",
    [Utils.assetSpritePath("objects/coin/coin_5_04.png")]: "coin-5-04",
    [Utils.assetSpritePath("objects/coin/coin_5_05.png")]: "coin-5-05",
    [Utils.assetSpritePath("objects/coin/coin_5_06.png")]: "coin-5-06",
    [Utils.assetSpritePath("objects/coin/coin_5_07.png")]: "coin-5-07",
  };

  static get defaultFont() {
    return "Press Start 2P";
  }

  static async loadAssets() {
    const assetsPaths = Object.keys(this.#assetsToLoad);
    return new Promise(resolve => {
      Loader.shared.add(assetsPaths)
        .load(() => {
          Object.keys(this.#assetsToLoad).forEach(path => {
            return this.#textures[this.#assetsToLoad[path]] = Loader.shared.resources[path].texture;
          });
          Utils.log("Assets loaded");
          resolve();
        });
    });
  }

  static getTexture(name) {
    return this.#textures[name] || null;
  }

  static getTextures(names) {
    return names.map(name => this.getTexture(name));
  }

  static getTexturesWithCount(name, count, reverted) {
    const text = [];
    for (let i = 0; i < count; i++) {
      const textName = name + "-" + ("0" + (reverted ? count - i - 1 : i)).slice(-2);
      text.push(this.getTexture(textName));
    }
    return text;
  }
}