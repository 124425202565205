export default class UserGame {
  userId = null;
  highestDistance = 0;
  highestCoins = 0;
  totalGames = 0;

  constructor(data) {
    this.userId = data?.id;
    this.highestDistance = data?.runnerHighestDistance ?? 0;
    this.highestCoins = data?.runnerHighestCoins ?? 0;
    this.totalGames = data?.runnerTotalGames ?? 0;
  }
}