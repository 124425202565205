import Entity from "../../core/entity.js";
import ResourcesLoader from "../../core/resources-loader.js";
import {Sprite} from "pixi.js";
import Utils from "../../core/utils/utils";

export default class Bridge extends Entity {
  #bridge = null;

  constructor(parent, assetName, width, height, zIndex) {
    super(parent, zIndex);
    this.#bridge = new Sprite(ResourcesLoader.getTexture(assetName));
    this.#bridge.height = height;
    this.#bridge.width = width;
    this.#bridge.anchor.y = 1;
    this.container.addChild(this.#bridge);
  }

  get isOffScreen() {
    return this.y - this.#bridge.height > Utils.screenHeight;
  }
}