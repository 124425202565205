import Entity from "../core/entity.js";
import GameEvent from "../core/utils/game-event.js";
import Utils from "../core/utils/utils.js";
import ResourcesLoader from "../core/resources-loader.js";
import PrimaryButton from "../components/primary-button.js";
import {Graphics, Sprite, Text} from "pixi.js";
import AppState from "../data/models/app-state.js";
import EnvUtils from "../data/env-utils.js";
import EventBridge from "../core/event-bridge.js";
import LogoButton from "../components/logo-button.js";

/**
 * MainMenuScene
 */
export default class MainMenuScene extends Entity {
  #highScoreText = null;
  #highScoreTextValue = null;
  #title = null;
  #titleEffect = null;
  #scaleDirection = 1;

  onNewGameClicked = new GameEvent();
  onInfoClicked = new GameEvent();

  constructor(parent) {
    super(parent);

    const bgContainer = new Sprite(ResourcesLoader.getTexture("bg-main-menu"));
    bgContainer.width = Utils.screenWidth;
    bgContainer.height = Utils.screenHeight;
    this.container.addChild(bgContainer);

    const titleBackground = new Sprite(ResourcesLoader.getTexture("bg-container"));
    const ratio = titleBackground.width / titleBackground.height;
    titleBackground.width = Utils.screenWidth;
    titleBackground.height = titleBackground.width / ratio;
    titleBackground.y = 200;
    this.container.addChild(titleBackground);

    this.#titleEffect = new Sprite(ResourcesLoader.getTexture("reflect-main-menu"));
    this.#titleEffect.scale.set(Utils.scale / 2, Utils.scale / 2);
    this.#titleEffect.y = titleBackground.y + titleBackground.height / 2 - this.#titleEffect.height / 2;
    this.#titleEffect.x = -this.#titleEffect.width;
    this.container.addChild(this.#titleEffect);

    this.#title = new Sprite(ResourcesLoader.getTexture("title-main-menu"));
    this.#title.anchor.set(0.5, 0.5);
    this.#title.scale.set(Utils.scale / 2, Utils.scale / 2);
    this.#title.y = titleBackground.y + titleBackground.height / 2;
    this.#title.x = Utils.screenWidthCenter;
    this.container.addChild(this.#title);

    const descriptionText = new Text("Récoltez un maximum de pièces\npour gravir les paliers\net gagner des codes promos", {
      fontFamily: ResourcesLoader.defaultFont,
      align: "center",
      fontSize: 30,
      fill: "white",
      dropShadow: true,
      dropShadowDistance: 10,
      dropShadowAlpha: 0.4,
      lineHeight: 40
    });
    descriptionText.anchor.x = 0.5;
    descriptionText.anchor.y = 0;
    descriptionText.x = Utils.screenWidthCenter;
    descriptionText.y = titleBackground.y + titleBackground.height + 100;
    descriptionText.zIndex = 1000;
    this.container.addChild(descriptionText);

    if (AppState.instance.canPlay) {

      // highScore
      this.#highScoreText = new Text("HIGH SCORE", {fontFamily: ResourcesLoader.defaultFont, fontSize: 50, fill: "white"});
      this.#highScoreText.anchor.x = 0.5;
      this.#highScoreText.anchor.y = 0;
      this.#highScoreText.x = Utils.screenWidthCenter;
      this.#highScoreText.y = descriptionText.y + descriptionText.height + 100;
      this.#highScoreText.zIndex = 1000;

      this.#highScoreTextValue = new Text("0", {fontFamily: ResourcesLoader.defaultFont, fontSize: 70, fill: "white"});
      this.#highScoreTextValue.anchor.x = 0.5;
      this.#highScoreTextValue.anchor.y = 0;
      this.#highScoreTextValue.x = Utils.screenWidthCenter;
      this.#highScoreTextValue.y = this.#highScoreText.y + this.#highScoreText.height + 50;
      this.#highScoreTextValue.zIndex = 1000;
      this.#highScoreTextValue.text = AppState.instance.userGames.highestDistance + "m";

      const background = new Graphics();
      background.beginFill(0x000000, 0.3);
      background.drawRect(0, this.#highScoreText.y - 40, Utils.screenWidth, (this.#highScoreTextValue.y + this.#highScoreTextValue.height + 40) - (this.#highScoreText.y - 40));
      background.endFill();
      background.interactive = true; // block clicking through

      this.container.addChild(background);
      this.container.addChild(this.#highScoreTextValue);
      this.container.addChild(this.#highScoreText);

      const newGameButton = new PrimaryButton(this, "JOUER", {x: Utils.screenWidthCenter, y: this.#highScoreTextValue.y + this.#highScoreTextValue.height * 2 + 200});
      newGameButton.onClick.subscribe(() => this.onNewGameClicked.trigger());

      const quitButton = new LogoButton(this, "btn-quit", "QUITTER", {width: Utils.menuButtonSize, height: Utils.menuButtonSize, x: 40, y: Utils.screenHeight - 40});
      quitButton.onClick.subscribe(() => EventBridge.emit("action.quit"));

      const helpButton = new LogoButton(this, "btn-help", null, {
        width: Utils.menuButtonSize,
        height: Utils.menuButtonSize,
        x: Utils.screenWidth - 40 - (Utils.menuButtonSize * Utils.scale),
        y: Utils.screenHeight - 40
      });
      helpButton.onClick.subscribe(() => this.onInfoClicked.trigger());
    }

    if (!EnvUtils.isProduction()) {
      // Debug only
      const userName = new Text(AppState.instance.user.firstname + " " + AppState.instance.user.lastname, {
        fontFamily: ResourcesLoader.defaultFont,
        fontSize: 20,
        fill: "white"
      });
      userName.anchor.x = 0.5;
      userName.anchor.y = 0;
      userName.x = Utils.screenWidthCenter;
      userName.y = 20;
      this.container.addChild(userName);
    }
  }

  onDestroy() {
    this.onNewGameClicked.clear();
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);

    this.#titleEffect.x += 5 * deltaTime;
    if (this.#titleEffect.x > Utils.screenWidth * 10) {
      this.#titleEffect.x = -this.#titleEffect.width;
    }

    const scaleSpeed = 0.01;
    this.#title.scale.x = Utils.lerp(this.#title.scale.x, this.#title.scale.x + this.#scaleDirection * scaleSpeed, deltaTime * scaleSpeed);
    this.#title.scale.y = Utils.lerp(this.#title.scale.y, this.#title.scale.y + this.#scaleDirection * scaleSpeed, deltaTime * scaleSpeed);

    if (this.#title.scale.x > 2.05) {
      this.#scaleDirection = -this.#scaleDirection;
    } else if (this.#title.scale.x < 1.95) {
      this.#scaleDirection = -this.#scaleDirection;
    }
  }
}