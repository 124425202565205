export default class GameEvent {
  #listeners = [];

  subscribe(callback) {
    this.#listeners.push(callback);
  }

  unsubscribe(callback) {
    this.#listeners = this.#listeners.filter(l => l !== callback);
  }

  trigger(...args) {
    this.#listeners.forEach(l => l(...args));
  }

  clear() {
    this.#listeners = [];
  }
}