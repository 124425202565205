import HitBoxDebug from "./hit-box-debug.js";

export default class HitBox {
  static DEFAULT_MASK = 0;
  static PLAYER_MASK = 1;
  static DECOR_MASK = 2;

  static maskMatrix = {
    [HitBox.DEFAULT_MASK]: {
      [HitBox.DEFAULT_MASK]: true,
      [HitBox.PLAYER_MASK]: true,
      [HitBox.DECOR_MASK]: true
    },
    [HitBox.PLAYER_MASK]: {
      [HitBox.PLAYER_MASK]: false,
      [HitBox.DECOR_MASK]: true
    },
    [HitBox.DECOR_MASK]: {
      [HitBox.DECOR_MASK]: false
    }
  };

  enabled = true;
  followEntity = true;
  debug = false;
  debugHitBoxEntity = null;
  #margins = {x: 0, y: 0, width: 0, height: 0};

  x = 0;
  y = 0;
  width = 0;
  height = 0;
  mask = HitBox.DEFAULT_MASK;

  constructor(worldX, worldY, width, height, debug) {
    this.width = width;
    this.height = height;
    this.debug = debug;
    this.setPosition(worldX, worldY);
  }

  destroy() {
    this.debugHitBoxEntity?.destroy();
  }

  setMask(mask) {
    this.mask = mask;
    return this;
  }

  setMargins(margins) {
    this.#margins = margins;
    this.width += margins.width;
    this.height += margins.height;
    this.x = this.x + this.#margins?.x;
    this.y = this.y + this.#margins?.y;
    return this;
  }

  setFollowEntity(follow) {
    this.followEntity = follow;
    return this;
  }

  setPosition(x, y) {
    this.x = x + this.#margins?.x;
    this.y = y + this.#margins?.y;

    if (this.debug) { // we need to always destroy and recreate to keep debug on top of all displayed assets
      this.debugHitBoxEntity?.destroy();
      this.debugHitBoxEntity = new HitBoxDebug(this);
    }
  }

  hit(other) {
    const r1 = this;
    const r2 = other;

    if (!r1.enabled || !r2.enabled) {
      return false;
    }

    if (HitBox.maskMatrix[r1.mask][r2.mask] === false || HitBox.maskMatrix[r2.mask][r1.mask] === false) {
      return false;
    }

    //Find the center points of each sprite
    const r1centerX = r1.x + r1.width / 2;
    const r1centerY = r1.y + r1.height / 2;
    const r2centerX = r2.x + r2.width / 2;
    const r2centerY = r2.y + r2.height / 2;

    //Find the half-widths and half-heights of each sprite
    const r1halfWidth = r1.width / 2;
    const r1halfHeight = r1.height / 2;
    const r2halfWidth = r2.width / 2;
    const r2halfHeight = r2.height / 2;

    //Calculate the distance vector between the sprites
    const vx = r1centerX - r2centerX;
    const vy = r1centerY - r2centerY;

    //Figure out the combined half-widths and half-heights
    const combinedHalfWidths = r1halfWidth + r2halfWidth;
    const combinedHalfHeights = r1halfHeight + r2halfHeight;

    //Check for a collision on the x axis
    if (Math.abs(vx) < combinedHalfWidths) {
      return Math.abs(vy) < combinedHalfHeights;
    }
    return false;
  }
}