export default class EnvUtils {

  static getFirebaseConfig() {
    return {
      apiKey: process.env.FIREBASE_API_KEY,
      authDomain: process.env.FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.FIREBASE_DATABASE_URL,
      projectId: process.env.FIREBASE_PROJECT_ID,
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.FIREBASE_ID,
      measurementId: process.env.FIREBASE_ANALYTICS // process.env.FIREBASE_MEASUREMENT_ANALYTICS
    };
  }

  static isProduction() {
    return process.env.GAME_ENV === "production";
  }

  static getBaseApiUrl() {
    return process.env.BASE_API_URL;
  }
}
