/**
 * App State
 */
export default class AppState {
  static instance = new AppState();

  userId = null;
  orderId = null;
  canPlay = false;
  user = null; // User
  userGames = null; // UserGames
}
