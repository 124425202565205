/**
 * Game State
 */
export default class GameState {
  static instance = new GameState();
  static steps = [
    {coins: 30, promo: 1, step: 1},
    {coins: 60, promo: 2, step: 2},
    {coins: 90, promo: 3.5, step: 3},
  ]

  highestCoins = 0;

  distance = 0;
  coins = 0;
  duration = 0;
  /** @type {Date} */
  startedAt = null;
  endedAt = null;

  reset() {
    this.distance = 0;
    this.coins = 0;
    this.duration = 0;
    this.startedAt = new Date();
  }

  static getStepForCoins(coins) {
    let unlockedStep = null;
    GameState.steps.forEach((step) => {
      if (coins >= step.coins) {
        unlockedStep = step;
      }
    });
    return unlockedStep;
  }
}