import Entity from "../core/entity.js";
import Utils from "../core/utils/utils.js";
import ResourcesLoader from "../core/resources-loader.js";
import PrimaryButton from "../components/primary-button.js";
import {Sprite, Text} from "pixi.js";
import GameState from "../game/game-state.js";
import GameEvent from "../core/utils/game-event.js";

/**
 * InfoScene
 */
export default class InfoScene extends Entity {

  onBackClicked = new GameEvent();

  constructor(parent) {
    super(parent);

    const bgContainer = new Sprite(ResourcesLoader.getTexture("bg-main-menu"));
    bgContainer.width = Utils.screenWidth;
    bgContainer.height = Utils.screenHeight;
    this.container.addChild(bgContainer);

    const titleBackground = new Sprite(ResourcesLoader.getTexture("bg-container"));
    titleBackground.width = Utils.screenWidth;
    titleBackground.height = 250 * Utils.scale;
    titleBackground.anchor.y = 0.5;
    titleBackground.y = Utils.screenHeightCenter;
    this.container.addChild(titleBackground);

    const titleText = new Text("Comment jouer ?", {
      fontFamily: ResourcesLoader.defaultFont,
      align: "center",
      fontSize: 55,
      fill: "white",
      dropShadow: true,
      dropShadowDistance: 10,
      dropShadowAlpha: 0.4,
      lineHeight: 40
    });
    titleText.anchor.x = 0.5;
    titleText.anchor.y = 0;
    titleText.x = Utils.screenWidthCenter;
    titleText.y = titleBackground.y - titleBackground.height / 2 + 100;
    titleText.zIndex = 1000;
    this.container.addChild(titleText);

    const descriptionText = new Text("Atteignez les différents\n paliers en récoltant\n des pièces et recevez\nun code promo après\nla livraison de\nvotre commande", {
      fontFamily: ResourcesLoader.defaultFont,
      align: "center",
      fontSize: 30,
      fill: "white",
      dropShadow: true,
      dropShadowDistance: 10,
      dropShadowAlpha: 0.4,
      lineHeight: 40
    });
    descriptionText.anchor.x = 0.5;
    descriptionText.anchor.y = 0;
    descriptionText.x = Utils.screenWidthCenter;
    descriptionText.y = titleText.y + 150;
    descriptionText.zIndex = 1000;
    this.container.addChild(descriptionText);

    GameState.steps.forEach((step, index) => {
      const coin = new Sprite(ResourcesLoader.getTexture("coin"));
      coin.scale.set(Utils.scale, Utils.scale);

      const firstStep = new Text("x" + step.coins + " = Code promo " + step.promo + "€", {
        fontFamily: ResourcesLoader.defaultFont,
        align: "center",
        fontSize: 30,
        fill: "white",
        dropShadow: true,
        dropShadowDistance: 10,
        dropShadowAlpha: 0.4,
        lineHeight: 40
      });
      firstStep.anchor.x = 0.5;
      firstStep.x = Utils.screenWidthCenter + coin.width / 2 + 10;
      firstStep.y = descriptionText.y + descriptionText.height + 100 + index * 120;
      this.container.addChild(firstStep);

      coin.anchor.x = 1;
      coin.anchor.y = 0.5;
      coin.x = firstStep.x - firstStep.width / 2 - 10;
      coin.y = firstStep.y + 15;
      this.container.addChild(coin);
    });

    const backButton = new PrimaryButton(this, "MENU", {x: Utils.screenWidthCenter, y: titleBackground.y + titleBackground.height / 2 + 50 * Utils.scale});
    backButton.onClick.subscribe(() => this.onBackClicked.trigger());
  }
}