import {initializeApp} from "firebase/app";
import {initializeAuth, signInWithCustomToken} from "firebase/auth";
import EnvUtils from "./env-utils.js";
import AppState from "./models/app-state.js";
import User from "./models/user.js";
import UserGame from "./models/user-game.js";
import GameState from "../game/game-state.js";

export default class DataManager {
  static instance = new DataManager();

  #baseApiUrl = null;
  #firebaseApp = null;
  #firebaseAuth = null;

  constructor() {
    if (DataManager.instance) {
      throw new Error("Already initialized");
    }

    this.#firebaseApp = initializeApp(EnvUtils.getFirebaseConfig());
    this.#firebaseAuth = initializeAuth(this.#firebaseApp);
    this.#baseApiUrl = EnvUtils.getBaseApiUrl();
  }

  login(token, orderId) {
    if (!token) {
      if (EnvUtils.isProduction()) {
        throw new Error("Token not provided");
      }
      AppState.instance.canPlay = true;
      AppState.instance.user = new User({firstname: "test", lastname: "Test", id: ""});
      AppState.instance.userGames = new UserGame({});
      return;
    }

    AppState.instance.orderId = orderId;

    return signInWithCustomToken(this.#firebaseAuth, token)
      .then(userCredential => {
        AppState.instance.userId = userCredential.user.uid;
        return this.#getCurrentUser()
          .then(() => this.checkUserCanPlay())
          .then(() => AppState.instance.canPlay && this.#getUserGames());
      })
      .catch(error => {
        AppState.instance.userId = null;
        throw error;
      });
  }

  async saveRecord() {
    const orderId = AppState.instance.orderId;

    const response = await fetch(`${this.#baseApiUrl}/game/me/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await this.#firebaseAuth.currentUser.getIdToken()}`
      },
      body: JSON.stringify({
        coins: GameState.instance.coins,
        distance: Math.round(GameState.instance.distance),
        startedAt: GameState.instance.startedAt.toISOString(),
        duration: Math.round((GameState.instance.endedAt - GameState.instance.startedAt) / 1000)
      })
    });

    if (!response.ok) {
      throw new Error('Failed to save record');
    }
  }

  async checkUserCanPlay() {
    const orderId = AppState.instance.orderId;

    const response = await fetch(`${this.#baseApiUrl}/game/me/${orderId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${await this.#firebaseAuth.currentUser.getIdToken()}`
      }
    });

    AppState.instance.canPlay = response.ok;
    if (!response.ok) {
      throw new Error('Failed to check if user can play');
    }

    const data = await response.json();
    const records = data.records ?? [];
    GameState.instance.highestCoins = Math.max(...records.map(r => r.coins), 0);

    return AppState.instance.canPlay;
  }

  async #getCurrentUser() {
    const response = await fetch(`${this.#baseApiUrl}/user/me`, {
      headers: {
        'Authorization': `Bearer ${await this.#firebaseAuth.currentUser.getIdToken()}`
      }
    });

    if (!response.ok) {
      AppState.instance.user = null;
      throw new Error('Failed to get current user');
    }

    const data = await response.json();
    AppState.instance.user = new User(data);
    return AppState.instance.user;
  }

  async #getUserGames() {
    const response = await fetch(`${this.#baseApiUrl}/game/me/account`, {
      headers: {
        'Authorization': `Bearer ${await this.#firebaseAuth.currentUser.getIdToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to get user games');
    }

    const data = await response.json();
    AppState.instance.userGames = new UserGame(data);

    return AppState.instance.userGames;
  }
}
