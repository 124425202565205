export default class User {
  id = null;
  firstname = "";
  lastname = "";

  constructor(data) {
    this.id = data?.id;
    this.firstname = data?.firstname;
    this.lastname = data?.lastname;
  }
}