import Entity from "../core/entity.js";
import MainMenuScene from "../scenes/main-menu-scene.js";
import IngameScene from "../scenes/ingame-scene.js";
import GameState from "./game-state.js";
import LoadingScene from "../scenes/loading-scene.js";
import DataManager from "../data/data-manager.js";
import UnauthorizedScene from "../scenes/unauthorized-scene.js";
import AppState from "../data/models/app-state.js";
import InfoScene from "../scenes/info-scene.js";

/**
 * A game
 */
export default class Game extends Entity {
  #screen = null;

  constructor(parent) {
    super(parent);
    this.showLoading();
  }

  redirectToScreen() {
    if (AppState.instance.canPlay && AppState.instance.userGames.totalGames > 0) {
      this.showMainMenu();
    } else if (AppState.instance.canPlay && AppState.instance.userGames.totalGames === 0) {
      this.showInfo();
    } else {
      this.showUnauthorized();
    }
  }

  showUnauthorized() {
    this.#screen?.destroy();
    this.#screen = new UnauthorizedScene(this);
  }

  showMainMenu() {
    this.#screen?.destroy();
    const mainScreen = new MainMenuScene(this);

    // On player click new game, reset gameState datas and show ingame screen
    mainScreen.onNewGameClicked.subscribe(() => {
      GameState.instance.reset();
      this.showIngame();
    });

    mainScreen.onInfoClicked.subscribe(() => {
      this.showInfo();
    });
    this.#screen = mainScreen;
  }

  showIngame() {
    this.#screen?.destroy();
    const ingameScene = new IngameScene(this);

    // On player click replay, reset gameState datas and show ingame screen
    ingameScene.onPlayerRespawn.subscribe(() => {
      GameState.instance.reset();
      DataManager.instance.checkUserCanPlay()
        .then((canPlay) => (canPlay) ? this.showIngame() : this.showUnauthorized());
    });

    // On player killed, send game stats as records
    ingameScene.onPlayerKilled.subscribe(() => {
      GameState.instance.endedAt = new Date();
      GameState.instance.highestCoins = Math.max(GameState.instance.highestCoins, GameState.instance.coins);
      DataManager.instance.saveRecord()
        .catch(error => this.showUnauthorized());
    });
    this.#screen = ingameScene;
  }

  showInfo() {
    this.#screen?.destroy();
    const infoScene = new InfoScene(this);
    infoScene.onBackClicked.subscribe(() => this.showMainMenu());
    this.#screen = infoScene;
  }

  showLoading() {
    this.#screen?.destroy();
    this.#screen = new LoadingScene(this);
  }
}
