import Entity from "../entity.js";
import GameEvent from "./game-event.js";
import HitBox from "./hit-box.js";

export default class BoxCollider extends Entity {
  onHitEnter = new GameEvent();
  onHit = new GameEvent();
  // onHitLeave = new GameEvent();

  #hits = {};
  #followEntity = null;

  constructor(parent, x, y, width, height, followEntity, debug, zIndex) {
    super(parent, zIndex);
    this.#followEntity = followEntity;

    const hitBox = new HitBox(0, 0, width, height, debug)
      .setMargins({x: x, y: y, width: 0, height: -10})
      .setMask(HitBox.DEFAULT_MASK);

    this.setHitBox(hitBox);
  }

  onCollisionEnter(otherEntity) {
    this.onHit.trigger(otherEntity);
    if (!this.#hits[otherEntity.id]) {
      this.onHitEnter.trigger(otherEntity);
      this.#hits[otherEntity.id] = otherEntity;
    }
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);
    if (this.#followEntity) {
      this.x = this.#followEntity.x;
      this.y = this.#followEntity.y;
    }
  }
}
