import Entity from "../core/entity.js";
import Utils from "../core/utils/utils.js";
import RoadPart from "./road-part.js";
import RoadConfig from "../data/config/road-config.js";
import Bridge from "./decors/bridge.js";
import CivilCar from "./decors/vehicles/civil-car.js";
import PoliceCar from "./decors/vehicles/police-car.js";
import Coin from "./objects/coin.js";
import Scooter from "./decors/vehicles/scooter.js";
import Firefighters from "./decors/vehicles/firefighters.js";
import Ambulance from "./decors/vehicles/ambulance.js";
import Bus from "./decors/vehicles/bus.js";

export default class Road extends Entity {
  static allowRoadPartsToHaveDifferentPositions = false;
  // size of a sprite to calculate ratio and position correctly
  static #baseRoadPartSize = {width: 150, height: 100, drivingWidth: 90};
  static #speedMultiplierVisual = 0.045;

  // size of a sprite AFTER device scaling
  #roadPartSize = {width: 0, height: 0, drivingWidth: 0}; // initialized later
  #roadParts = [];
  #speed = 0;
  #counter = 0; // number of road sprites passed under Y

  #roadDecors = []; // entities
  #roadObstacles = []; // entities
  #coins = []; // entities

  constructor(parent, zIndex) {
    super(parent, zIndex);
    this.#generatePartRoads();
  }

  setSpeed(playerSpeed) {
    this.#speed = playerSpeed;
  }

  /**
   * Get the road part currently occupying this Y position
   * @return NULL | RoadPart
   */
  getRoadPartAt(yPosition) {
    return this.#roadParts.find(p => p.isPositionYIncluded(yPosition));
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);

    const speedDistance = this.#speed * Road.#speedMultiplierVisual;
    const addYDistance = speedDistance * deltaTime;

    this.#roadParts.forEach((roadPart, i) => {
      if (roadPart.y - this.#roadPartSize.height > Utils.screenHeight) {
        const oppositeIndex = (i + 1) % this.#roadParts.length;
        roadPart.y = this.#roadParts[oppositeIndex].y - this.#roadPartSize.height;

        this.#counter++;
        this.#createRoadAndDecor(roadPart, this.#counter, false);
      }
      roadPart.y += addYDistance;
    });

    const newRoadDecors = [];
    this.#roadDecors.forEach(decor => {
      if (decor.isOffScreen) {
        decor.destroy();
      } else {
        decor.y += addYDistance;
        newRoadDecors.push(decor);
      }
    });
    this.#roadDecors = newRoadDecors;

    const newCoins = [];
    this.#coins.forEach(coin => {
      if (coin.isOffScreen) {
        coin.destroy();
      } else {
        coin.y += addYDistance;
        newCoins.push(coin);
      }
    });
    this.#coins = newCoins;
  }

  #createRoadAndDecor(roadPart, counter, initialRoadCreation) {
    const road = "road-" + ("0" + counter % RoadConfig.numberOfRoads).slice(-2);
    const roadConfig = RoadConfig.getConfigFor(road);
    roadPart.setRoadConfig(roadConfig);

    const createBridge = roadConfig.hasBridges() && Math.random() > 0.5;
    const createGroundObstacle = !createBridge && Math.random() > 0.75;
    const createCar = Math.random() > 0;
    const createCoin = Math.random() > 0;

    if (createBridge) {
      const bridgeAssetName = roadConfig.bridges[0];
      const bridge = new Bridge(this.parentEntity, bridgeAssetName, Utils.screenWidth, this.#roadPartSize.height, 20);
      bridge.y = roadPart.y;
      this.#roadDecors.push(bridge);
    }

    if (createCar && !initialRoadCreation) {
      const rnd = Math.random();
      let car;

      if (rnd <= 0.10) {
        const scooters = ["scooter-cyan-00", "scooter-green-00"];
        car = new Scooter(this.parentEntity, scooters[Math.floor(Math.random() * scooters.length)], Math.random() * 4 + 1.5, 2);
      } else if (rnd <= 0.15) {
        car = new Firefighters(this.parentEntity, Math.random() * 4 + 1.5, 2);
      } else if (rnd <= 0.23) {
        car = new Ambulance(this.parentEntity, Math.random() * 4 + 1.5, 2);
      } else if (rnd <= 0.28) {
        car = new Bus(this.parentEntity, "bus-yellow-00", Math.random() * 4 + 1.5, 2);
      } else if (rnd <= 0.40) {
        car = new PoliceCar(this.parentEntity, Math.random() * 4 + 1.5, 2);
      } else {
        const cars = ["car-blue-00", "car-green-00", "car-red-00", "car-taxi-00"];
        car = new CivilCar(this.parentEntity, cars[Math.floor(Math.random() * cars.length)], Math.random() * 4 + 1.5, 2);
      }

      car.y = roadPart.y;
      car.x = roadPart.getRandomAvailablePositions();
      this.#roadDecors.push(car);
    }

    if (createGroundObstacle) {

    }

    if (createCoin) {
      const randomValue = Math.random();
      const shouldSpawn = 0.45;
      let coinValue = -1;
      if (randomValue < shouldSpawn * 0.05) {
        coinValue = 5;
      } else if (randomValue < shouldSpawn * 0.12) {
        coinValue = 3;
      } else if (randomValue < shouldSpawn) {
        coinValue = 1;
      }

      if (coinValue > 0) {
        this.#coins.push(new Coin(this.parentEntity, roadPart.getRandomAvailablePositions(), roadPart.y, coinValue, 1));
      }
    }
  }

  #generatePartRoads() {
    const ratio = Utils.screenWidth / Road.#baseRoadPartSize.width;
    this.#roadPartSize = {
      height: Road.#baseRoadPartSize.height * ratio,
      width: Utils.screenWidth,
      drivingWidth: Road.#baseRoadPartSize.drivingWidth * ratio
    };

    const numberOfPartsNeeded = Math.ceil(Utils.screenHeight / this.#roadPartSize.height + 1);
    for (let i = 0; i < numberOfPartsNeeded; i++) {
      const part = new RoadPart(this.parentEntity, Utils.screenWidth, this.#roadPartSize.height, this.#roadPartSize.drivingWidth, 3, 0);
      part.x = Utils.screenWidthCenter;
      part.y = i * this.#roadPartSize.height;
      this.#createRoadAndDecor(part, i, true);
      this.#roadParts.push(part);
    }
  }
}
