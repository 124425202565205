import Entity from "../core/entity.js";
import {Sprite} from "pixi.js";
import ResourcesLoader from "../core/resources-loader.js";
import Utils from "../core/utils/utils.js";
import GameState from "../game/game-state.js";

export default class ScoreBar extends Entity {
  #collectedCoins = 0;
  #fullAt = 0;

  #progressFillTop = null;
  #progressFill = null;
  #progressBackground = null;
  #progressStep = null;

  #scaleFactor = Utils.scale * 1.5;

  constructor(parent, zIndex, fullAt) {
    super(parent, zIndex);
    this.#fullAt = fullAt;

    this.#progressBackground = new Sprite(ResourcesLoader.getTexture("progress-background"));
    this.#progressBackground.scale.set(this.#scaleFactor, this.#scaleFactor);
    this.#progressBackground.y = Utils.screenHeight - this.#progressBackground.height - 80;
    this.container.addChild(this.#progressBackground);

    this.#draw();
  }

  setCollectedCoins(coins) {
    this.#collectedCoins = (coins <= this.#fullAt) ? coins : this.#fullAt;
    this.#progressFillTop.destroy();
    this.#progressFill.destroy();
    this.#progressStep.destroy();
    this.#draw();
  }

  #draw() {
    this.#progressFill = new Sprite(ResourcesLoader.getTexture("progress-fill"));
    this.#progressFill.scale.set(this.#scaleFactor, this.#scaleFactor);
    this.#progressFill.anchor.set(0, 1);
    // 9px is the distance between x origin of progress-background and left of fillable container
    this.#progressFill.x = 9 * this.#scaleFactor;
    // 98px is the distance between y origin of progress-background and bottom of fillable container
    this.#progressFill.y = this.#progressBackground.y + 98 * this.#scaleFactor;
    // 28px is the distance between y origin of progress-background and top of fillable container
    const percentage = this.#collectedCoins / this.#fullAt;
    this.#progressFill.height = this.#collectedCoins * (this.#progressBackground.height - 28 * this.#scaleFactor) / this.#fullAt;
    this.container.addChild(this.#progressFill);

    this.#progressFillTop = new Sprite(ResourcesLoader.getTexture("progress-fill-top"));
    this.#progressFillTop.scale.set(this.#scaleFactor, this.#scaleFactor * Math.min(1, Math.max(0.1, 0.1 + percentage)));
    this.#progressFillTop.anchor.set(0, 1);
    this.#progressFillTop.x = 9 * this.#scaleFactor;
    this.#progressFillTop.y = this.#progressFill.y - this.#progressFill.height;
    this.container.addChild(this.#progressFillTop);

    this.#progressStep = this.#getProgressStep();
    this.#progressStep.scale.set(this.#scaleFactor, this.#scaleFactor);
    this.#progressStep.y = Utils.screenHeight - this.#progressBackground.height - 80;
    this.container.addChild(this.#progressStep);
  }

  #getProgressStep() {
    const coins = Math.max(GameState.instance.highestCoins, this.#collectedCoins);
    const step = GameState.getStepForCoins(coins);
    if (step != null) {
      return new Sprite(ResourcesLoader.getTexture("progress-steps-" + step.step));
    }
    return new Sprite(ResourcesLoader.getTexture("progress-steps-0"));
  }
}