import {Application, SCALE_MODES, settings} from "pixi.js";
import Utils from "./utils/utils.js";
import ResourcesLoader from "./resources-loader.js";
import Game from "../game/game.js";

export default class World {
  static instance = null;

  static build() {
    this.instance = new World();
    return this.instance;
  }

  static addEntity(entity, parentEntity) {
    Object.defineProperty(entity, "id", {
      value: Math.random().toString(),
      writable: false,
      enumerable: true,
      configurable: true
    });

    parentEntity ? parentEntity.addEntity(entity) : this.instance.#entities.push(entity);
    entity.container.setParent(parentEntity ? parentEntity.container : this.instance.#app.stage);
    return entity.id;
  }

  static removeEntity(entity) {
    this.instance.#entities = this.instance.#entities.filter(e => e.id !== entity.id);
  }

  static addEntityForCollision(entity) {
    this.instance.#hitBoxEntities.push(entity);
  }

  static removeEntityForCollision(entity) {
    this.instance.#hitBoxEntities = this.instance.#hitBoxEntities.filter(e => e.id !== entity.id);
  }

  #app = null;
  #isInitialized = false;
  #entities = [];
  #hitBoxEntities = [];
  #activeGame = null;

  constructor() {
    Utils.log("Creating game...");
    const width = window.innerWidth;
    const height = window.innerHeight;

    settings.SCALE_MODE = SCALE_MODES.NEAREST;

    this.#app = new Application({width: 400, height: 200, antialias: false});
    this.#app.renderer.backgroundColor = 0x7e4646;
    this.#app.renderer.view.style.position = "absolute";
    this.#app.renderer.view.style.display = "block";
    this.#app.renderer.autoDensity = true;

    this.#app.renderer.resize(width, height);
    document.body.appendChild(this.#app.view);
  }

  async init() {
    if (!this.#isInitialized) {
      this.newGame();
      await ResourcesLoader.loadAssets();
      this.#isInitialized = true;
      this.#app.ticker.add((delta) => this.#gameLoop(delta * (1000 / 60)));
      this.#activeGame.redirectToScreen();
    }
    return this;
  }

  newGame() {
    this.#entities.forEach(e => e.destroy());
    this.#entities = [];
    this.#activeGame = new Game();
  }

  #gameLoop(deltaTime) {
    this.#entities.forEach(entity => entity.onUpdate(deltaTime));

    for (let i = 0; i < this.#hitBoxEntities.length; i++) {
      const e1 = this.#hitBoxEntities[i];
      for (let k = i + 1; k < this.#hitBoxEntities.length; k++) {
        const e2 = this.#hitBoxEntities[k];
        if (e1.getHitBox().hit(e2.getHitBox())) {
          e1.onCollisionEnter(e2);
          e2.onCollisionEnter(e1);
        }
      }
    }
  }
}
