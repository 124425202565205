import {AnimatedSprite} from "pixi.js";
import Entity from "../../core/entity.js";
import Utils from "../../core/utils/utils.js";
import ResourcesLoader from "../../core/resources-loader.js";
import HitBox from "../../core/utils/hit-box.js";

export default class Coin extends Entity {
  #value = 1;
  #coin = null;
  #ySpeed = null; // screen speed

  constructor(parent, x, y, value, zIndex) {
    super(parent, zIndex);

    this.#value = value;

    const textures = ResourcesLoader.getTexturesWithCount(`coin-${value}`, 8).concat(ResourcesLoader.getTexturesWithCount(`coin-${value}`, 8, true));
    this.#coin = new AnimatedSprite(textures);
    this.#coin.anchor.x = 0.5;
    this.#coin.anchor.y = 1;
    this.#coin.scale.set(Utils.scale, Utils.scale);

    this.#coin.animationSpeed = 0.15;
    this.#coin.play();

    this.container.addChild(this.#coin);
    this.x = x;
    this.y = y;

    const hitBox = new HitBox(this.x, this.y, this.#coin.width, this.#coin.height, false)
      .setMargins({x: -this.#coin.width / 2, y: -this.#coin.height - 15, width: 0, height: -15})
      .setMask(HitBox.DEFAULT_MASK);

    this.setHitBox(hitBox);
  }

  setYSpeed(ySpeed) {
    this.#ySpeed = ySpeed;
  }

  get isOffScreen() {
    return this.y - this.#coin.height > Utils.screenHeight;
  }

  getValue() {
    return this.#value;
  }
}