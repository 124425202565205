import BaseVehicle from "./baseVehicle.js";

export default class Firefighters extends BaseVehicle {

  #speedSpecialLights = 450; // ms
  #specialLights = [];
  #totalTime = 0;
  #previousTime = 0;

  constructor(parent, speed, zIndex) {
    super(parent, "firefighters-00", speed, zIndex);

    this.#specialLights = (this.config.specialLights ?? []).map((light, i) => {
      const vLight = BaseVehicle.createVehicleLight(this.vehicle, light);
      this.container.addChild(vLight);
      vLight.visible = i % 2 === 0;
      return vLight;
    });
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);
    if (this.active) {
      this.#totalTime += deltaTime;
      if (this.#totalTime - this.#previousTime >= this.#speedSpecialLights) {
        this.#previousTime = this.#totalTime;
        this.#specialLights.forEach(light => light.visible = !light.visible);
      }
    }
  }
}
