import Entity from "../../../core/entity.js";
import {NineSlicePlane, Text, TextMetrics, TextStyle} from "pixi.js";
import ResourcesLoader from "../../../core/resources-loader.js";
import TalkConfig from "../../../data/config/talk-config.js";
import Utils from "../../../core/utils/utils.js";
import BoxCollider from "../../../core/utils/box-collider.js";
import Player from "../../player.js";

export default class Bubble extends Entity {
  static #maxCharsPerLine = 20;
  static #defaultPadding = {left: 30, right: 20, top: 20, bottom: 35, perLine: 10};

  #bubble = null;
  #followedEntity = null;
  #talkConfig = null;
  #hideIn = null;
  #showed = false;

  constructor(parent, talkConfig, followedEntity, zIndex) {
    super(parent, zIndex);
    this.#followedEntity = followedEntity;
    this.#talkConfig = talkConfig;

    const randomText = talkConfig.text[Math.floor(Math.random() * talkConfig.text.length)];
    const splitText = randomText.split(" ");
    const lines = [""];
    splitText.forEach(part => {
      if (lines[lines.length - 1].length + part.length > Bubble.#maxCharsPerLine) {
        lines.push(""); // create new line
      }
      lines[lines.length - 1] += part + " ";
    });

    const finalText = lines.join("\n");
    let style = new TextStyle({fontFamily: ResourcesLoader.defaultFont, fontSize: 22, fill: "black"});
    let textMetrics = TextMetrics.measureText(finalText, style);

    const ninePatchSizes = {x1: 30, y1: 20, x2: 20, y2: 20};
    const finalWidth = textMetrics.maxLineWidth + Bubble.#defaultPadding.left + Bubble.#defaultPadding.right;

    this.#bubble = new NineSlicePlane(ResourcesLoader.getTexture("bubble-00"), ninePatchSizes.x1, ninePatchSizes.y1, ninePatchSizes.x2, ninePatchSizes.y2);
    this.#bubble.width = finalWidth;
    this.#bubble.height = (textMetrics.lineHeight + Bubble.#defaultPadding.perLine) * lines.length + Bubble.#defaultPadding.top + Bubble.#defaultPadding.bottom;
    this.#bubble.x = this.#talkConfig.x;
    this.#bubble.y = this.#talkConfig.y;
    this.#bubble.interactive = false;
    this.container.addChild(this.#bubble);

    const pixiText = new Text(finalText, style);
    pixiText.anchor.set(0.5);
    pixiText.height = (textMetrics.lineHeight + Bubble.#defaultPadding.perLine) * lines.length;
    pixiText.x = this.#bubble.x + this.#bubble.width / 2;
    pixiText.y = this.#bubble.y + this.#bubble.height / 2;
    this.container.addChild(pixiText);

    if (talkConfig.trigger) {
      this.container.visible = false;

      if (talkConfig.trigger === TalkConfig.playerCloseTrigger) {
        this.#setupCloseToPlayerTrigger();
      }

    } else {
      this.#showed = true;
    }
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);
    if (this.#followedEntity) {
      this.x = this.#followedEntity.x;
      this.y = this.#followedEntity.y;
    }

    // check if duration is reached
    if (this.#showed && this.#talkConfig.trigger && this.container.visible) {
      this.#hideIn -= deltaTime;
      if (this.#hideIn <= 0) {
        this.container.visible = false;
      }
    }

    if (this.#talkConfig.trigger && !this.#showed) {
      let show = false;
      if (this.#talkConfig.trigger === TalkConfig.enterScreenTrigger) {
        if (this.y >= 0 && this.y <= Utils.screenHeight && this.x >= 0 && this.x <= Utils.screenWidth) {
          show = true;
        }
      }

      if (show) {
        this.container.visible = true;
        this.#showed = true;
        this.#hideIn = this.#talkConfig.duration ?? 1000;
      }
    }
  }

  #setupCloseToPlayerTrigger() {
    const closePlayer = new BoxCollider(this, -200, -200, 400, 400, this, false);
    closePlayer.onHitEnter.subscribe((otherEntity) => {
      if (otherEntity instanceof Player) {
        this.container.visible = true;
        this.#showed = true;
        this.#hideIn = this.#talkConfig.duration ?? 1000;
      }
    });
    this.addEntity(closePlayer);
  }

}