import Entity from "../core/entity.js";
import Utils from "../core/utils/utils.js";
import ResourcesLoader from "../core/resources-loader.js";
import {Graphics, Text} from "pixi.js";

/**
 * LoadingScene
 */
export default class LoadingScene extends Entity {
  constructor(parent) {
    super(parent);

    const background = new Graphics();
    background.beginFill(0x000000);
    background.drawRect(0, 0, Utils.screenWidth, Utils.screenHeight);
    background.endFill();
    background.interactive = true; // block clicking through
    this.container.addChild(background);

    const loadingText = new Text("Chargement...", {fontFamily: ResourcesLoader.defaultFont, fontSize: 30, fill: "white"});
    loadingText.anchor.x = 0.5;
    loadingText.anchor.y = 0;
    loadingText.x = Utils.screenWidthCenter;
    loadingText.y = Utils.screenHeightCenter;
    loadingText.zIndex = 1000;
    this.container.addChild(loadingText);
  }
}