/**
 */
import TalkConfig from "./talk-config.js";

export default class DecorConfig {
  static getConfigFor(assetName) {
    if (!decorConfiguration[assetName]) {
      console.warn(`Decor config not found : ${assetName}`);
    }
    return decorConfiguration[assetName] ?? new DecorConfig({assetName: assetName});
  }

  constructor(data) {
    this.assetName = data?.assetName;
  }

  assetName = null;
}

export class VehicleConfig extends DecorConfig {
  brakeLights = [];
  specialLights = [];
  talks = [];
  colliderMargins = null;

  constructor(data) {
    super(data);
    this.brakeLights = data?.brakeLights ?? [];
    this.specialLights = data?.specialLights ?? [];
    this.talks = data?.talks ?? [];
    this.colliderMargins = data?.colliderMargins ?? null;
  }
}

const decorConfiguration = {
  "car-blue-00": new VehicleConfig({
    assetName: "car-blue-00",
    brakeLights: [{assetName: "red-light-00", x: 15, y: 45}, {assetName: "red-light-00", x: 34, y: 45}]
  }),
  "car-red-00": new VehicleConfig({
    assetName: "car-red-00",
    brakeLights: [{assetName: "red-light-00", x: 15, y: 45}, {assetName: "red-light-00", x: 34, y: 45}]
  }),
  "car-green-00": new VehicleConfig({
    assetName: "car-green-00",
    brakeLights: [{assetName: "red-light-00", x: 15, y: 45}, {assetName: "red-light-00", x: 34, y: 45}]
  }),
  "car-police-00": new VehicleConfig({
    assetName: "car-police-00",
    brakeLights: [{assetName: "red-light-00", x: 15, y: 45}, {assetName: "red-light-00", x: 34, y: 45}],
    specialLights: [{assetName: "blue-light-00", x: 25, y: 31}],
    talks: [{
      x: 30,
      y: -220,
      text: ["GO Frères Toque!", "Woah!", "Miam..", "Trop fort !", "Ça sent la pizza...", "Le missile 🚀"],
      trigger: TalkConfig.playerCloseTrigger,
      duration: 2100,
      revertX: -30,
      revertY: -220
    }]
  }),
  "car-taxi-00": new VehicleConfig({
    assetName: "car-taxi-00",
    brakeLights: [{assetName: "red-light-00", x: 15, y: 45}, {assetName: "red-light-00", x: 34, y: 45}]
  }),
  "ambulance-00": new VehicleConfig({
    assetName: "ambulance-00",
    colliderMargins: {left: 26, right: 34, top: 0, bottom: -18},
    brakeLights: [{assetName: "red-light-00", x: 13, y: 53}, {assetName: "red-light-00", x: 28, y: 53}],
    specialLights: [{assetName: "blue-light-00", x: 19, y: 20}, {assetName: "blue-light-00", x: 23, y: 20}],
  }),
  "firefighters-00": new VehicleConfig({
    assetName: "firefighters-00",
    colliderMargins: {left: 26, right: 34, top: 0, bottom: -8},
    brakeLights: [{assetName: "red-light-00", x: 10, y: 70}, {assetName: "red-light-00", x: 31, y: 70}],
    specialLights: [{assetName: "blue-light-00", x: 15, y: 14}, {assetName: "blue-light-00", x: 26, y: 14}],
    talks: [{
      x: 30,
      y: -220,
      text: ["Pin Pon !"],
      trigger: TalkConfig.playerCloseTrigger,
      duration: 2100,
      revertX: -30,
      revertY: -220
    }]
  }),
  "scooter-cyan-00": new VehicleConfig({
    assetName: "scooter-cyan-00",
    colliderMargins: {left: 20, right: 25, top: 0, bottom: -5},
    brakeLights: [{assetName: "red-light-00", x: 13, y: 35}]
  }),
  "scooter-green-00": new VehicleConfig({
    assetName: "scooter-green-00",
    colliderMargins: {left: 20, right: 25, top: 0, bottom: -5},
    brakeLights: [{assetName: "red-light-00", x: 13, y: 35}]
  }),
  "bus-yellow-00": new VehicleConfig({
    assetName: "bus-yellow-00",
    colliderMargins: {left: 26, right: 34, top: 0, bottom: -8},
    brakeLights: [{assetName: "red-light-00", x: 15, y: 45}, {assetName: "red-light-00", x: 34, y: 45}]
  })
};
