import Entity from "../core/entity.js";
import ResourcesLoader from "../core/resources-loader.js";
import Utils from "../core/utils/utils.js";
import {Sprite} from "pixi.js";

export default class RoadPart extends Entity {
  #size = {width: 0, height: 0};
  #availablePositions = [];

  #sprite = null;

  constructor(parent, width, height, drivingWidth, numberOfPositions, zIndex) {
    super(parent, zIndex);
    this.#size = {width: width, height: height};

    const widthPerPosition = drivingWidth / numberOfPositions;
    const initialMargin = (Utils.screenWidth - drivingWidth) / 2.0;
    for (let i = 0; i < numberOfPositions; i++) {
      this.#availablePositions.push(initialMargin + widthPerPosition / 2 + i * widthPerPosition);
    }

    // draw UI
    this.#sprite = new Sprite();
    this.#sprite.anchor.x = 0.5;
    this.#sprite.anchor.y = 1;
    this.#sprite.height = height;
    this.#sprite.width = width;
    this.container.addChild(this.#sprite);
  }

  /**
   * @param config RoadConfig
   */
  setRoadConfig(config) {
    this.#sprite.texture = ResourcesLoader.getTexture(config.assetName);
  }

  getAvailablePositions() {
    // if needed, each block can return different available positions
    return this.#availablePositions;
  }

  getRandomAvailablePositions() {
    const positions = this.#availablePositions;
    return positions[Math.floor(Math.random() * positions.length)];
  }

  isPositionYIncluded(yPosition) {
    return this.y <= yPosition && (this.y + this.#size.height) >= yPosition
  }
}