import Entity from "../../core/entity.js";
import {Graphics} from "pixi.js";

export default class HitBoxDebug extends Entity {
  debug = null;

  constructor(hitBox) {
    super(null, 500);
    this.debug = new Graphics();
    this.debug.alpha = 1.0;
    this.debug.lineStyle(4, 0xF700FF);
    this.debug.drawRect(hitBox.x, hitBox.y, hitBox.width, hitBox.height);
    this.debug.endFill();
    this.debug.interactive = false;
    this.container.addChild(this.debug);
  }
}