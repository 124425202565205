import Entity from "../../../core/entity.js";
import ResourcesLoader from "../../../core/resources-loader.js";
import {Sprite} from "pixi.js";
import Utils from "../../../core/utils/utils.js";
import HitBox from "../../../core/utils/hit-box.js";
import DecorConfig from "../../../data/config/decor-config.js";
import Bubble from "../bubbles/bubble.js";
import BoxCollider from "../../../core/utils/box-collider.js";

export default class BaseVehicle extends Entity {
  static #carSize = {
    height: 100,
    width: 100
  };

  config = null;
  vehicle = null;
  #initialSpeed = 0;
  #speed = 0;

  #boxColliderFront = null;
  #boxColliderFrontDistances = {};

  #brakeIntensity = 0;
  #brakeLights = [];

  #activeTalks = []; // {entity, createdAt}

  constructor(parent, configName, speed, zIndex) {
    super(parent, zIndex);
    this.config = DecorConfig.getConfigFor(configName);

    this.#speed = speed;
    this.#initialSpeed = speed;

    this.vehicle = new Sprite(ResourcesLoader.getTexture(this.config.assetName));
    this.vehicle.height = BaseVehicle.#carSize.height;
    this.vehicle.width = BaseVehicle.#carSize.width;
    this.vehicle.anchor.y = 1;
    this.vehicle.anchor.x = 0.5;
    this.vehicle.scale.set(Utils.scale, Utils.scale);
    this.container.addChild(this.vehicle);

    const hitBox = new HitBox(this.x, this.y, this.vehicle.width / 2, this.vehicle.height, false)
      .setMargins({
        x: this.config.colliderMargins ? (-this.vehicle.width / 2 + this.config.colliderMargins.left) : this.vehicle.width / 4 - this.vehicle.width / 2,
        y: -this.vehicle.height + (this.config.colliderMargins?.top ?? 10),
        width: this.config.colliderMargins?.right ?? 0,
        height: this.config.colliderMargins?.bottom ?? -10
      })
      .setMask(HitBox.DECOR_MASK);

    this.setHitBox(hitBox);

    this.#boxColliderFront = new BoxCollider(this, -10, -this.vehicle.height + 10 - 120, 20, 120, this, false);
    this.#boxColliderFront.onHit.subscribe((otherEntity) => {
      if (otherEntity instanceof BaseVehicle) {
        const distance = this.y - otherEntity.y - otherEntity.container.height;
        const previousDistance = this.#boxColliderFrontDistances[otherEntity.id] ?? null;
        this.#boxColliderFrontDistances[otherEntity.id] = distance;
        this.#brakeIntensity = 1 + ((previousDistance ?? distance) - distance);
      }
    });
    this.addEntity(this.#boxColliderFront);

    this.#brakeLights = (this.config.brakeLights ?? []).map(light => {
      const vLight = BaseVehicle.createVehicleLight(this.vehicle, light);
      this.container.addChild(vLight);
      return vLight;
    });

    (this.config.talks ?? []).forEach(talkConfig => {
      this.addEntity(new Bubble(this.parentEntity, talkConfig, this, 11));
    });
  }

  setSpeed(newSpeed) {
    this.#speed = newSpeed;
  }

  onDestroy() {
    super.onDestroy();
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);

    if (this.active) {
      this.y += deltaTime * -this.#speed * 0.05;
      this.#boxColliderFront.x = this.x;
      this.#boxColliderFront.y = this.y;

      if (this.#brakeIntensity > 0) {
        this.#brakeLights.forEach(light => light.visible = true);
        this.#speed -= deltaTime * 0.0015 * this.#brakeIntensity;
        this.#brakeIntensity = 0;
      } else if (this.#initialSpeed > this.#speed) {
        this.#speed += deltaTime * 0.0005;
        this.#brakeLights.forEach(light => light.visible = false);
      }
    }
    // this.#brakeLights.forEach(light => light.visible = true);
  }

  get isOffScreen() {
    return this.y - this.vehicle.height > Utils.screenHeight;
  }

  static createVehicleLight(vehicle, light) {
    const sprite = new Sprite(ResourcesLoader.getTexture(light.assetName));
    sprite.anchor.y = 0.5;
    sprite.anchor.x = 0.5;
    sprite.alpha = 0.85;
    sprite.x = -vehicle.width * vehicle.anchor.x + light.x * Utils.scale; // adapt to new anchor and scale
    sprite.y = -vehicle.height * vehicle.anchor.y + light.y * Utils.scale;
    sprite.scale.set(Utils.scale, Utils.scale);
    sprite.visible = false;
    return sprite;
  }
}
