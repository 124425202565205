import Entity from "../core/entity.js";
import Utils from "../core/utils/utils.js";
import Road from "../components/road.js";
import ResourcesLoader from "../core/resources-loader.js";
import Player from "../components/player.js";
import {Graphics, Sprite, Text} from "pixi.js";
import SideTouchController from "../components/controllers/side-touch-controller.js";
import GameEvent from "../core/utils/game-event.js";
import PrimaryButton from "../components/primary-button.js";
import {OldFilmFilter} from "pixi-filters";
import EventBridge from "../core/event-bridge.js";
import GameState from "../game/game-state.js";
import ScoreBar from "../components/score-bar.js";

/**
 * IngameScene
 */
export default class IngameScene extends Entity {
  onPlayerRespawn = new GameEvent();
  onPlayerKilled = new GameEvent();

  #scoreText = null;
  #speedText = null;
  #coinText = null;

  #scoreBar = null;

  #player = null;
  #playerController = null;
  #road = null;
  #sky = null;

  #stepTextGameOver = null;
  #stepTitleGameOver = null;
  #scaleDirection = 1;

  constructor(parent) {
    super(parent);
    this.container.sortableChildren = true;
    // this.container.filters = [new AdvancedBloomFilter()];

    this.#drawGameObjects();
    this.#drawUI();
    this.#attachEventHandlers();
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);

    if (this.#stepTitleGameOver !== null && this.#stepTextGameOver !== null) {
      const scaleSpeed = 0.01;
      this.#stepTitleGameOver.scale.x = Utils.lerp(this.#stepTitleGameOver.scale.x, this.#stepTitleGameOver.scale.x + this.#scaleDirection * scaleSpeed, deltaTime * scaleSpeed);
      this.#stepTitleGameOver.scale.y = Utils.lerp(this.#stepTitleGameOver.scale.y, this.#stepTitleGameOver.scale.y + this.#scaleDirection * scaleSpeed, deltaTime * scaleSpeed);
      this.#stepTextGameOver.scale.x = Utils.lerp(this.#stepTextGameOver.scale.x, this.#stepTextGameOver.scale.x + this.#scaleDirection * scaleSpeed, deltaTime * scaleSpeed);
      this.#stepTextGameOver.scale.y = Utils.lerp(this.#stepTextGameOver.scale.y, this.#stepTextGameOver.scale.y + this.#scaleDirection * scaleSpeed, deltaTime * scaleSpeed);

      if (this.#stepTitleGameOver.scale.x > 1.05) {
        this.#scaleDirection = -this.#scaleDirection;
      } else if (this.#stepTitleGameOver.scale.x < 0.95) {
        this.#scaleDirection = -this.#scaleDirection;
      }
    }
  }

  #drawGameObjects() {
    const gameEntity = new Entity(this, 0);
    gameEntity.container.sortableChildren = true;

    const background = new Graphics();
    background.beginFill(Utils.toColor("#c58a8a"));
    background.drawRect(0, 0, Utils.screenWidth, Utils.screenHeight);
    background.endFill();
    gameEntity.container.addChild(background);

    this.#road = new Road(gameEntity, 0);
    this.#player = new Player(gameEntity, 10);

    this.#playerController = new SideTouchController(gameEntity, 999);
    this.#player.setRoad(this.#road);
    this.#player.controlledBy(this.#playerController);
    this.#player.setPosition(1);
    this.#player.playerKilledStarted.subscribe(() => {
      gameEntity.container.filters = [
        new OldFilmFilter({vignettingAlpha: 0.2, sepia: 0.1, vignetting: 0.1, noise: 0.1})
      ];
    });
    this.#player.playerKilled.subscribe(() => {
      gameEntity.container.filters = [new OldFilmFilter({vignettingAlpha: 0.2, sepia: 0.5, vignetting: 0.2, noise: 0.1})];
      this.onPlayerKilled.trigger();
      this.#drawGameOverUI();
    });
    this.#player.coinPickedUp.subscribe((coin) => {
      GameState.instance.coins += coin.getValue();
      this.#coinText.text = GameState.instance.coins;
      this.#scoreBar.setCollectedCoins(GameState.instance.coins);
    });

    //this.#sky = new Sky(this, 50);
    //this.#sky.setYSpeed(10);
    //this.#player.speedChanged.subscribe(speed => this.#sky.setYSpeed(speed));
  }

  #drawUI() {
    // score
    this.#scoreText = new Text("0", {fontFamily: ResourcesLoader.defaultFont, fontSize: 50, fill: "orange"});
    this.#scoreText.anchor.x = 0.5;
    this.#scoreText.anchor.y = 0;
    this.#scoreText.x = Utils.screenWidthCenter;
    this.#scoreText.y = Utils.screenStatusBarHeight;
    this.#scoreText.zIndex = 1000;
    this.container.addChild(this.#scoreText);
    // speed
    this.#speedText = new Text("0", {fontFamily: ResourcesLoader.defaultFont, fontSize: 30, fill: "white"});
    this.#speedText.anchor.x = 0.5;
    this.#speedText.anchor.y = 0;
    this.#speedText.x = Utils.screenWidthCenter;
    this.#speedText.y = this.#scoreText.y + this.#scoreText.height + 20;
    this.#speedText.zIndex = 1000;
    this.container.addChild(this.#speedText);

    this.#scoreBar = new ScoreBar(this, 200, 105);

    this.#coinText = new Text("0", {fontFamily: ResourcesLoader.defaultFont, fontSize: 45, fill: "orange", dropShadow: true, dropShadowDistance: 10, dropShadowAlpha: 0.4});
    this.#coinText.anchor.x = 0.5;
    this.#coinText.anchor.y = 0;
    this.#coinText.x = Utils.scale * 1.5 * 12;
    this.#coinText.y = Utils.screenHeight - 18 * Utils.scale;
    this.#coinText.zIndex = 1000;
    this.container.addChild(this.#coinText);
  }

  #drawGameOverUI() {
    const background = new Graphics();
    background.alpha = 0.4;
    background.beginFill(0x000000);
    background.drawRect(0, 0, Utils.screenWidth, Utils.screenHeight);
    background.endFill();
    background.interactive = true; // block clicking through
    background.zIndex = 100;
    this.container.addChild(background);

    const bgContainer = new Sprite(ResourcesLoader.getTexture("bg-container"));
    bgContainer.width = Utils.screenWidth;
    bgContainer.height = 420;
    bgContainer.y = Utils.screenHeightCenter * 0.7 - bgContainer.height / 2;
    bgContainer.zIndex = 101;
    this.container.addChild(bgContainer);

    const gameOverText = new Text("GAME OVER", {fontFamily: ResourcesLoader.defaultFont, fontSize: 80, fill: "white", dropShadow: true, dropShadowDistance: 20, dropShadowAlpha: 0.4});
    gameOverText.x = Utils.screenWidthCenter - gameOverText.width / 2;
    gameOverText.y = bgContainer.y + 70;
    gameOverText.zIndex = 102;
    this.container.addChild(gameOverText);

    const scoreTextGameOver = new Text(this.#scoreText.text, {fontFamily: ResourcesLoader.defaultFont, fontSize: 60, fill: "white", dropShadow: true, dropShadowDistance: 20, dropShadowAlpha: 0.4});
    scoreTextGameOver.x = Utils.screenWidthCenter - scoreTextGameOver.width / 2;
    scoreTextGameOver.y = gameOverText.y + gameOverText.height + 30;
    scoreTextGameOver.zIndex = 102;
    this.container.addChild(scoreTextGameOver);

    const coinsTextGameOver = new Text(GameState.instance.coins + " coins", {
      fontFamily: ResourcesLoader.defaultFont,
      fontSize: 50,
      fill: "white",
      dropShadow: true,
      dropShadowDistance: 20,
      dropShadowAlpha: 0.4
    });
    coinsTextGameOver.x = Utils.screenWidthCenter - coinsTextGameOver.width / 2;
    coinsTextGameOver.y = scoreTextGameOver.y + scoreTextGameOver.height + 20;
    coinsTextGameOver.zIndex = 102;
    this.container.addChild(coinsTextGameOver);

    const step = GameState.getStepForCoins(GameState.instance.highestCoins);
    if (step != null) {
      this.#stepTitleGameOver = new Text("RECOMPENSE ACTUELLE", {
        fontFamily: ResourcesLoader.defaultFont,
        fontSize: 40,
        fill: "white",
        dropShadow: true,
        dropShadowDistance: 20,
        dropShadowAlpha: 0.4,
        align: "center"
      });
      this.#stepTitleGameOver.x = Utils.screenWidthCenter;
      this.#stepTitleGameOver.y = bgContainer.y + bgContainer.height + 100;
      this.#stepTitleGameOver.scale.set(Utils.scale / 4, Utils.scale / 4);
      this.#stepTitleGameOver.zIndex = 250;
      this.#stepTitleGameOver.anchor.set(0.5);
      this.container.addChild(this.#stepTitleGameOver);

      this.#stepTextGameOver = new Text("Code promo " + step.promo + "€", {
        fontFamily: ResourcesLoader.defaultFont,
        fontSize: 30,
        fill: "white",
        dropShadow: true,
        dropShadowDistance: 20,
        dropShadowAlpha: 0.4,
        align: "center"
      });

      this.#stepTextGameOver.x = Utils.screenWidthCenter;
      this.#stepTextGameOver.y = this.#stepTitleGameOver.y + 50;
      this.#stepTextGameOver.scale.set(Utils.scale / 4, Utils.scale / 4);
      this.#stepTextGameOver.zIndex = 250;
      this.#stepTextGameOver.anchor.set(0.5);
      this.container.addChild(this.#stepTextGameOver);
    }

    const startButtonsHeight = bgContainer.y + bgContainer.height + 400;
    const newGameButton = new PrimaryButton(this, "REJOUER", {x: Utils.screenWidthCenter, y: startButtonsHeight}, 102);
    newGameButton.onClick.subscribe(() => this.onPlayerRespawn.trigger());

    const quitGameButton = new PrimaryButton(this, "QUITTER", {x: Utils.screenWidthCenter, y: startButtonsHeight + 200}, 102);
    quitGameButton.onClick.subscribe(() => EventBridge.emit("action.quit"));
  }

  #attachEventHandlers() {
    let previousPlayerSpeedKmH = 0; // performance optimization to reduce text edition
    this.#player.speedChanged.subscribe((playerSpeedKmH) => {
      if (Math.abs(playerSpeedKmH - previousPlayerSpeedKmH) > 0.1) {
        previousPlayerSpeedKmH = playerSpeedKmH;
        this.#road.setSpeed(playerSpeedKmH);
        this.#speedText.text = playerSpeedKmH.toFixed(1) + "kmh";
      }
    });

    let previousTotalPlayerDistance = 0; // performance optimization to reduce text edition
    this.#player.distanceChanged.subscribe((totalPlayerDistance) => {
      GameState.instance.distance = totalPlayerDistance;
      if (Math.abs(totalPlayerDistance - previousTotalPlayerDistance) > 1) {
        previousTotalPlayerDistance = totalPlayerDistance;
        const meters = Math.round(totalPlayerDistance);
        this.#scoreText.text = meters + "m";
      }
    });
  }
}