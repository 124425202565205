import Entity from "../core/entity.js";
import GameEvent from "../core/utils/game-event.js";
import ResourcesLoader from "../core/resources-loader.js";
import {Sprite, Text} from "pixi.js";
import Utils from "../core/utils/utils.js";

export default class LogoButton extends Entity {
  onClick = new GameEvent();

  constructor(parent, image, text, config = {width: Utils.menuButtonSize, height: Utils.menuButtonSize, x: 0, y: 0}, zIndex) {
    super(parent, zIndex);

    const button = new Sprite(ResourcesLoader.getTexture(image));
    button.width = config.width * Utils.scale;
    button.height = config.height * Utils.scale;
    button.x = config.x;
    button.y = config.y;
    button.interactive = true;
    button.buttonMode = true;
    button.anchor.set(0, 1);
    button.on("pointertap", () => this.onClick.trigger());
    button.zIndex = 1000;
    this.container.addChild(button);

    if (text != null) {
      const txt = new Text(text, {fontFamily: ResourcesLoader.defaultFont, fontSize: 30, fill: "white", dropShadow: true, dropShadowDistance: 0, dropShadowAlpha: 0});
      txt.anchor.set(0, 1);
      txt.x = button.x + button.width + 20;
      txt.y = button.y - 10;
      txt.interactive = true;
      txt.buttonMode = true;
      txt.on("pointertap", () => this.onClick.trigger());
      this.container.addChild(txt);
    }
  }
}