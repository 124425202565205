import DataManager from "./data/data-manager.js";
import World from "./core/world.js";
import {utils} from "pixi.js";
import EventBridge from "./core/event-bridge.js";

utils.skipHello();

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop)
});

(async function () {
  try {
    await DataManager.instance.login(params?.token ?? null, params?.order ?? null);
    EventBridge.emit("status.ready");
    await World.build().init();
  } catch (err) {
    console.error(err);
  }
})();
