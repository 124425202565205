import Entity from "../core/entity.js";
import GameEvent from "../core/utils/game-event.js";
import ResourcesLoader from "../core/resources-loader.js";
import {NineSlicePlane, Text} from "pixi.js";
import Utils from "../core/utils/utils.js";

export default class PrimaryButton extends Entity {
  onClick = new GameEvent();

  constructor(parent, pixiText, config = {width: 400, height: 50, x: 0, y: 0}, zIndex) {
    super(parent, zIndex);

    const txt = new Text(pixiText, {fontFamily: ResourcesLoader.defaultFont, fontSize: 38, fill: "white", dropShadow: true, dropShadowDistance: 10, dropShadowAlpha: 0.4});

    const button = new NineSlicePlane(ResourcesLoader.getTexture("btn-background"), 40, 40, 40, 40);
    button.width = config.width ?? Utils.screenWidth / 2;
    button.height = (config.height ?? 100) + txt.height;
    button.x = config.x - button.width / 2.0;
    button.y = config.y - button.height / 2.0;
    button.interactive = true;
    button.buttonMode = true;
    button.on("pointerup", () => button.texture = ResourcesLoader.getTexture("btn-background-clicked"));
    button.on("pointertap", () => this.onClick.trigger());
    button.on("pointerupoutside", () => button.texture = ResourcesLoader.getTexture("btn-background"));
    button.on("pointerdown", () => button.texture = ResourcesLoader.getTexture("btn-background-clicked"));
    this.container.addChild(button);

    txt.anchor.set(0.5);
    txt.x = button.x + button.width / 2;
    txt.y = button.y + button.height / 2 + 5;

    this.container.addChild(txt);
  }
}