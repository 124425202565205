import Entity from "../core/entity.js";
import Utils from "../core/utils/utils.js";
import ResourcesLoader from "../core/resources-loader.js";
import PrimaryButton from "../components/primary-button.js";
import {Graphics, Sprite, Text} from "pixi.js";
import AppState from "../data/models/app-state.js";
import EnvUtils from "../data/env-utils.js";
import EventBridge from "../core/event-bridge.js";

/**
 * UnauthorizedScene
 */
export default class UnauthorizedScene extends Entity {

  constructor(parent) {
    super(parent);

    const background = new Graphics();
    background.beginFill(0xD11B21);
    background.drawRect(0, 0, Utils.screenWidth, Utils.screenHeight);
    background.endFill();
    background.interactive = true; // block clicking through
    this.container.addChild(background);

    // You can't play
    const cantPlayText = new Text("Aucune commande", {fontFamily: ResourcesLoader.defaultFont, fontSize: 50, fill: "white", align: "center"});
    cantPlayText.anchor.x = 0.5;
    cantPlayText.anchor.y = 0;
    cantPlayText.x = Utils.screenWidthCenter;
    cantPlayText.y = Utils.screenHeightCenter * 0.85;
    cantPlayText.zIndex = 1000;
    this.container.addChild(cantPlayText);
    // You can't play
    const cantPlayText2 = new Text("Vous ne pouvez\n\npas jouer !", {fontFamily: ResourcesLoader.defaultFont, fontSize: 32, fill: "white", align: "center"});
    cantPlayText2.anchor.x = 0.5;
    cantPlayText2.anchor.y = 0;
    cantPlayText2.x = Utils.screenWidthCenter;
    cantPlayText2.y = cantPlayText.y + cantPlayText.height + 50;
    cantPlayText2.zIndex = 1000;
    this.container.addChild(cantPlayText2);

    const logo = new Sprite(ResourcesLoader.getTexture("logo"));
    logo.scale.set(Utils.scale, Utils.scale);
    logo.x = Utils.screenWidthCenter - logo.width / 2;
    logo.y = cantPlayText.y - logo.height - 100;
    this.container.addChild(logo);

    const quitGameButton = new PrimaryButton(this, "QUITTER", {x: Utils.screenWidthCenter, y: cantPlayText2.y + cantPlayText2.height + 200}, 102);
    quitGameButton.onClick.subscribe(() => EventBridge.emit("action.quit"));

    // Debug only
    if (!EnvUtils.isProduction()) {
      const userName = new Text(AppState.instance.user.firstname + " " + AppState.instance.user.lastname, {
        fontFamily: ResourcesLoader.defaultFont,
        fontSize: 20,
        fill: "black"
      });
      userName.anchor.x = 0.5;
      userName.anchor.y = 0;
      userName.x = Utils.screenWidthCenter;
      userName.y = 20;
      this.container.addChild(userName);
    }
  }
}