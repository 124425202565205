import Controller from "./controller.js";
import Utils from "../../core/utils/utils.js";
import {Graphics} from "pixi.js";

export default class SideTouchController extends Controller {
  constructor(parent, zIndex) {
    super(parent, zIndex);
    const padding = 25;

    const left = new Graphics();
    left.beginFill(Utils.toColor("#3777FF"));
    left.alpha = 0.0;
    left.drawRect(0, Utils.screenHeight - Utils.screenHeight / 2.0, Utils.screenWidth / 2 - padding, Utils.screenHeight / 2.0);
    left.endFill();
    left.interactive = true;
    this.container.addChild(left);

    const right = new Graphics();
    right.beginFill(Utils.toColor("#ff0000"));
    right.alpha = 0.0;
    right.drawRect(Utils.screenWidth / 2 + padding, Utils.screenHeight - Utils.screenHeight / 2.0, Utils.screenWidth / 2 - padding, Utils.screenHeight / 2.0);
    right.endFill();
    right.interactive = true;
    this.container.addChild(right);

    left.on("pointerdown", () => this.onLeft.trigger());
    right.on("pointerdown", () => this.onRight.trigger());
  }
}