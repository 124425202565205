export default class Utils {
  static #cachedScreenSize = {
    width: 0,
    height: 0
  };

  static get scale() {
    return 4.0;
  }

  static get screenHeight() {
    if (Utils.#cachedScreenSize.height === 0) {
      Utils.#cachedScreenSize.height = window.innerHeight;
    }
    return Utils.#cachedScreenSize.height;
  }

  static get screenWidth() {
    if (Utils.#cachedScreenSize.width === 0) {
      Utils.#cachedScreenSize.width = window.innerWidth;
    }
    return Utils.#cachedScreenSize.width;
  }

  static get screenWidthCenter() {
    return this.screenWidth / 2.0;
  }

  static get screenHeightCenter() {
    return this.screenHeight / 2.0;
  }

  static get screenStatusBarHeight() {
    return 80;
  }

  static get menuButtonSize() {
    return 16;
  }

  static log(data) {
    console.log(data);
  }

  static assetSpritePath(sprite) {
    return `assets/sprites/${sprite}`;
  }

  static assetFontPath(font) {
    return `assets/fonts/${font}`;
  }

  static assetSoundPath(sound) {
    return `assets/sounds/${sound}`;
  }

  static toColor(hex) {
    return parseInt(hex.slice(1), 16);
  }

  static distanceInteractionEvent(a, b) {
    if (!a || !b) {
      return {x: 0, y: 0};
    }

    return {
      x: a.x - b.x,
      y: a.y - b.y
    };
  }

  static lerp(a, b, c) {
    return a + (b - a) * c;
  }

  static randomIndex(array) {
    return Math.floor(Math.random() * array.length);
  }
}