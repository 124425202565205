import {Container} from "pixi.js";
import World from "./world.js";

export default class Entity {
  id = null;
  x = 0;
  y = 0;

  parentEntity = null;
  container = new Container();
  #entities = [];
  active = true;
  #hitBox = null;

  constructor(parentEntity, zIndex) {
    this.parentEntity = parentEntity;
    this.container.zIndex = zIndex ?? 0;
    World.addEntity(this, parentEntity);
  }

  setActive(value) {
    this.active = value;
  }

  setHitBox(hitBox) {
    if (hitBox) {
      this.#hitBox = hitBox;
      World.addEntityForCollision(this);
    } else if (!hitBox && this.#hitBox) {
      this.#hitBox = null;
      World.removeEntityForCollision(this);
    }
  }

  getHitBox = () => this.#hitBox;

  onCollisionEnter(otherEntity) {
  }

  onUpdate(deltaTime) {
    if (this.active) {
      this.container.x = this.x;
      this.container.y = this.y;
      if (this.#hitBox?.followEntity) {
        this.#hitBox?.setPosition(this.x, this.y);
      }

      this.#entities.forEach(component => component.onUpdate(deltaTime));
    }
  }

  onDestroy() {
  }

  addEntity(entity) {
    entity.parentEntity = this;
    this.#entities.push(entity);
  }

  destroy() {
    this.#hitBox?.destroy();
    World.removeEntityForCollision(this);
    this.#entities.forEach(child => child.destroy());
    this.onDestroy();
    this.container.removeChildren();
    this.container.parent?.removeChild(this.container);

    if (this.parentEntity) {
      this.parentEntity.#entities = this.parentEntity.#entities.filter(e => e.id !== this.id);
    } else {
      World.removeEntity(this);
    }
    this.parentEntity = null;
  }
}