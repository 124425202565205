import GameEvent from "../core/utils/game-event.js";
import Utils from "../core/utils/utils.js";
import Entity from "../core/entity.js";
import {AnimatedSprite} from "pixi.js";
import ResourcesLoader from "../core/resources-loader.js";
import Road from "./road.js";
import HitBox from "../core/utils/hit-box.js";
import BaseVehicle from "./decors/vehicles/baseVehicle.js";
import Coin from "./objects/coin.js";

export default class Player extends Entity {
  static gameOverSprites = ["gameover-00", "gameover-01", "gameover-02", "gameover-02", "gameover-03", "gameover-03", "gameover-04", "gameover-04", "gameover-05", "gameover-05"];
  static #maxSpeed = 65.0;
  static #animationSpeed = 1 / 120.0;
  static #minDistanceToAllowMove = 50;

  playerKilled = new GameEvent();
  playerKilledStarted = new GameEvent();
  speedChanged = new GameEvent();
  distanceChanged = new GameEvent();
  coinPickedUp = new GameEvent();

  #playerSprite = null;
  #road = null;
  #positions = [Utils.screenWidthCenter];
  #actualPositionIndex = 0;
  #nextPositionIndex = 0;
  #canMove = true;
  #speed = 15;
  #speedDirection = 0.66;
  #totalDistanceMeters = 0;
  #isDead = false;

  constructor(parent, zIndex) {
    super(parent, zIndex);
    this.#playerSprite = new AnimatedSprite(ResourcesLoader.getTextures(["biker-00", "biker-00", "biker-01", "biker-02", "biker-02", "biker-01"]));
    this.#playerSprite.anchor.x = 0.5;
    this.#playerSprite.anchor.y = 1;
    this.#playerSprite.animationSpeed = 0;
    this.#playerSprite.scale.set(Utils.scale, Utils.scale);
    this.#playerSprite.play();

    this.x = this.#positions[this.#actualPositionIndex];
    this.y = Utils.screenHeight - this.#playerSprite.height / 2;
    this.container.addChild(this.#playerSprite);

    const hitBox = new HitBox(this.x, this.y, this.#playerSprite.width, this.#playerSprite.height, false)
      .setMargins({x: 27 - this.#playerSprite.width / 2, y: 20 - this.#playerSprite.height, width: -40, height: -40})
      .setMask(HitBox.PLAYER_MASK);

    this.setHitBox(hitBox);
  }

  setRoad(road) {
    this.#road = road;
    this.#positions = this.#road.getRoadPartAt(this.y)?.getAvailablePositions();
  }

  setPosition(positionIndex) {
    if (this.#canMove && this.#positions.length > positionIndex && positionIndex >= 0 && this.#actualPositionIndex !== positionIndex) {
      this.#canMove = false;
      this.#nextPositionIndex = positionIndex;
    }
  }

  /**
   * Assign a controller to this player
   * @param  controller Controller
   */
  controlledBy(controller) {
    controller.onLeft.subscribe(() => {
      this.setPosition(Math.max(0, this.#actualPositionIndex - 1));
    });

    controller.onRight.subscribe(() => {
      this.setPosition(Math.min(this.#actualPositionIndex + 1, this.#positions.length));
    });
  }

  onUpdate(deltaTime) {
    super.onUpdate(deltaTime);

    if (Road.allowRoadPartsToHaveDifferentPositions) {
      // we get positions every time (to allow different background sprites with different positions)
      this.#positions = this.#road.getRoadPartAt(this.y).getAvailablePositions();
    }
    this.#canMove = !this.#isDead && this.#actualPositionIndex === this.#nextPositionIndex;

    const targetPositionX = this.#positions[this.#nextPositionIndex];
    const calculatedSpeedDirection = this.#speedDirection * (1 + this.#speed / Player.#maxSpeed);
    this.x = Utils.lerp(this.x, targetPositionX, 0.01 * calculatedSpeedDirection * deltaTime);

    if (!this.#canMove) {
      if (Math.abs(this.x - targetPositionX) < Player.#minDistanceToAllowMove) {
        this.#actualPositionIndex = this.#nextPositionIndex;
      }
    }

    this.#totalDistanceMeters += this.#speed / 3600.0 * deltaTime; // meters
    this.distanceChanged.trigger(this.#totalDistanceMeters);

    if (!this.#isDead && this.#speed < Player.#maxSpeed) {
      this.#setSpeed(this.#speed + Math.log(this.#speed) / 20000.0 * deltaTime);
    } else if (this.#isDead) {
      if (this.#speed > 0) {
        const newSpeed = this.#speed - (Math.log(this.#speed) + 1) / 100.0 * deltaTime;
        this.#setSpeed(newSpeed < 1 ? 0 : newSpeed);
      } else {
        this.active = false;
        this.playerKilled.trigger();
        this.playerKilled.clear();
      }
    }
  }

  onCollisionEnter(otherEntity) {
    if (otherEntity instanceof Coin) {
      this.coinPickedUp.trigger(otherEntity);
      otherEntity.destroy();
    } else if (otherEntity instanceof BaseVehicle && !this.#isDead) {
      this.playerKilledStarted.trigger();
      this.#isDead = true;
      const crash = new AnimatedSprite(ResourcesLoader.getTextures(Player.gameOverSprites));
      crash.animationSpeed = 0.4;
      crash.anchor.x = 0.5;
      crash.anchor.y = 1;
      crash.scale.set(Utils.scale, Utils.scale);
      crash.loop = false;
      crash.play();
      this.container.addChild(crash);
      this.#playerSprite.visible = false;
    }
  }

  #setSpeed(newSpeed) {
    this.#speed = Math.min(Player.#maxSpeed, newSpeed);
    if (this.#playerSprite.playing) {
      this.#playerSprite.animationSpeed = this.#speed * Player.#animationSpeed;
    }
    this.speedChanged.trigger(this.#speed);
  }
}