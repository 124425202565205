/**
 * Road configuration
 */
export default class RoadConfig {
  /**
   * Default roads configuration to manage UI and levels difficulties
   */
  static #roadConfiguration = {
    "road-00": RoadConfig.map({assetName: "road-00"}),
    "road-01": RoadConfig.map({assetName: "road-01"}),
    "road-02": RoadConfig.map({assetName: "road-02"}),
    "road-03": RoadConfig.map({assetName: "road-03"}),
    "road-04": RoadConfig.map({assetName: "road-04", bridges: ["bridge-00"]}),
    "road-05": RoadConfig.map({assetName: "road-05"}),
    "road-06": RoadConfig.map({assetName: "road-06"}),
    "road-07": RoadConfig.map({assetName: "road-07"}),
    "road-08": RoadConfig.map({assetName: "road-08"}),
    "road-09": RoadConfig.map({assetName: "road-09", bridges: ["bridge-00"]}),
    "road-10": RoadConfig.map({assetName: "road-10"}),
    "road-11": RoadConfig.map({assetName: "road-11"}),
    "road-12": RoadConfig.map({assetName: "road-12"}),
    "road-13": RoadConfig.map({assetName: "road-13", bridges: ["bridge-00"]}),
    "road-14": RoadConfig.map({assetName: "road-14"}),
    "road-15": RoadConfig.map({assetName: "road-15"})
  };

  static numberOfRoads = Object.keys(this.#roadConfiguration).length;

  static getConfigFor(assetName) {
    if (!this.#roadConfiguration[assetName]) {
      console.warn(`Road config not found : ${assetName}`);
    }
    return this.#roadConfiguration[assetName] ?? RoadConfig.map({bridges: ["bridge-00"]});
  }

  static map(data) {
    const config = new RoadConfig();
    config.assetName = data?.assetName;
    config.bridges = data?.bridges ?? [];
    return config;
  }

  assetName = null;
  bridges = []; // If array is not empty, allow bridges to be displayed

  hasBridges() {
    return this.bridges && this.bridges.length > 0;
  }
}